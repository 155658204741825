.jsoneditor {
  border: thin solid @primary-purple !important;
  .jsoneditor-menu {
    background-color: @primary-purple !important;
    border-bottom: solid 1px @primary-purple !important;
  }
  table.jsoneditor-values {
    width: auto !important;
  }
}
