// .cr-form-item {
//   &:extend(.mb-2);
//   .form-item {
//     position: relative;
//     border: 1px solid #e8defc;
//     border-radius: 10px;
//     &:extend(.pv-1);
//     &:extend(.ph-2);

//     .ant-select-selector {
//       border: none !important;
//       &:extend(.p-0);
//     }

//     .ant-form-item-label {
//       &:extend(.p-0);
//       label {
//         &:extend(.text-size-12);
//         &:extend(.text-height-15);
//         &:extend(.text-weight-medium);
//         &:extend(.text-color-second-dark-purple);
//         height: unset !important;
//       }
//     }

//     .ant-input-group-addon {
//       border: none !important;
//       background: none !important;
//       &:extend(.pl-0);
//     }

//     .ant-input {
//       &:extend(.pl-0);
//       box-shadow: none !important;
//       outline: none !important;

//       &:placeholder-shown {
//         &:extend(.text-size-16);
//         &:extend(.text-height-20);
//         &:extend(.text-color-third-dark-purple);
//       }
//     }

//     &:hover,
//     &:focus,
//     &:focus-within {
//       border-color: @primary-purple !important;
//     }

//     &.has-error {
//       border-color: @status-red !important;
//     }

//     &.ant-form-item-has-error {
//       &:extend(.mb-7);
//       border-color: @status-red !important;
//     }

//     .ant-input {
//       &:extend(.text-size-16);
//     }
//     .ant-input-prefix {
//       &:extend(.text-size-16);
//     }

//     .ant-form-item-label {
//       line-height: 0;
//     }
//   }

//   .ant-form-item-explain {
//     position: absolute;
//     bottom: calc(@padding-size * -5);
//     left: calc(@padding-size * -1);
//   }

//   .ant-input-password {
//     &:extend(.p-0);
//   }
// }

.form-modal {
  .ant-modal-footer {
    border-top: unset !important;
    &:extend(.ph-3);
    &:extend(.pb-3);
    &:extend(.p-0);
  }
  .ant-modal-body {
    &:extend(.pb-0);
  }
}

.field-json {
  .form-item {
    &:extend(.p-0);
  }
}
