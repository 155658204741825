.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-small {
  font-size: smaller;
}

each(@font-weights, {
  .text-s-@{key} {
      font-weight: @value !important;
      font-size: 12px !important;
      line-height: 16px !important;
  }
});

each(@font-weights, {
  .text-m-@{key} {
      font-weight: @value !important;
      font-size: 14px !important;
      line-height: 20px !important;
  }
});

each(@font-weights, {
  .text-l-@{key} {
      font-weight: @value !important;
      font-size: 16px !important;
      line-height: 24px !important;
  }
});

each(@font-weights, {
  .heading-s-@{key} {
      font-weight: @value !important;
      font-size: 20px !important;
      line-height: 28px !important;
  }
});

each(@font-weights, {
  .heading-m-@{key} {
      font-weight: @value !important;
      font-size: 28px !important;
      line-height: 36px !important;
  }
});

each(@font-weights, {
  .heading-l-@{key} {
      font-weight: @value !important;
      font-size: 36px !important;
      line-height: 44px !important;
  }
});
