.layout {
  min-height: 100vh;
  .ant-layout-sider-trigger {
    border-top: 1px solid @light-purple;
    &:extend(.text-color-white);
  }

  .ant-menu {
    &:extend(.ph-3);
    .ant-menu-submenu-title,
    .ant-menu-item {
      &:extend(.p-2);
      &:extend(.m-0);
      a {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
      }

      &.ant-menu-item-selected {
        background-color: @blue-primary !important;
        border-radius: @padding-size;

        .anticon,
        .ant-typography {
          &:extend(.text-color-white);
        }
      }
    }

    &.ant-menu-sub {
      background: transparent !important;
      &:extend(.pl-3);
    }
  }

  .ant-menu-item::after {
    border-right: unset !important;
  }
}

.layout .logo {
  height: calc(@padding-size * 4);
  background-image: url('https://noctua.gg/avatar.svg');
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  color: @white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:extend(.m-2);
  &:extend(.pl-5);
  &:extend(.text-color-primary-purple);
  &:extend(.text-weight-bold);
}

.layout.collapsed .logo {
  color: transparent;
}

.layout .header {
  background-color: @white;
  color: @primary;
  text-transform: capitalize;
  &:extend(.ph-2);
  &:extend(.text-weight-medium);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  .ant-breadcrumb {
    min-height: @header-size;
    display: flex;
    align-items: center;
  }
}

.layout .profile {
  display: flex;
  align-items: center;
}

.layout .content {
  padding: calc(@padding-size * 4);
}

.layout.login {
  background-color: @primary;
}

.login-form-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:extend(.p-2);
}

.login-form {
  background-color: @white;
  padding: calc(@avatar-size + calc(@padding-size * 2)) calc(@padding-size * 2)
    calc(@padding-size * 2) calc(@padding-size * 2);
  border: calc(@padding-size * 2);
  position: relative;
  margin-top: calc(@avatar-size + calc(@padding-size * 2));
}

.login-form::before {
  content: ' ';
  height: calc(@avatar-size * 2);
  width: calc(@avatar-size * 2);
  background-image: url('https://noctua.gg/avatar.svg');
  background-color: #fff;
  border-radius: 50%;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: calc(@avatar-size * -1);
  left: calc(50% - @avatar-size);
}

.image-responsive {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.form-title {
  font-size: 14px;
  color: @font-form;
}

.form-block {
  width: 100%;
}

.ant-layout {
  background-color: @white;
}

.ant-layout-footer {
  background-color: @white;
}

.ant-layout-sider-children {
  background: #001529 !important;
}

.ant-menu-light {
  background: transparent;
}

.sidemenu {
  position: sticky;
  top: 0px;
  height: 100vh;
  overflow: auto;
  width: 100%;
}

.side-logo {
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
