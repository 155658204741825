.array-item {
  &:extend(.ph-2);
  &:extend(.pv-1);
  &:extend(.mb-2);
  border-radius: @padding-size;
  border: solid 1px @third-dark-purple;
}

.metadata-form {
  .jsoneditor-outer {
    height: 56vh;
  }
}
