@import '~antd/dist/antd.less';
@import './antd-custom.less';

@import './component/typography.less';
@import './component/layout.less';
@import './component/header.less';
@import './component/default-form.less';
@import './component/advance-meta-editor.less';
@import './component/json-editor.less';

/* 
* typography
*/

@font-sizes: 10, 11, 12, 14, 16, 18, 20, 24, 27, 28, 32, 40, 48;
each(@font-sizes, {
  .text-size-@{value} {
    font-size: ~"@{value}px" !important;
  }
});

@font-heights: 12, 15, 18, 20, 21, 24, 28, 30, 32, 36, 48, 60;
each(@font-heights, {
  .text-height-@{value} {
    line-height: ~"@{value}px" !important;
  }
});

@font-weights: {
  regular: 400;
  medium: 500;
  semibold: 600;
  bold: 700;
};
each(@font-weights, {
  .text-weight-@{key} {
    font-weight: @value !important;
  }
});

/* 
 * text color
 */
@color-vars: {
  primary-purple: @primary-purple;
  white: @white;
  gray: @gray;
  dark-purple: @dark-purple;
  second-dark-purple: @second-dark-purple;
  third-dark-purple: @third-dark-purple;
  status-green: @status-green;
  status-red: @status-red;
  purple-bg: @purple-bg;
  divider: @divider;
  neutral-10: @neutral-10;
  neutral-20: @neutral-20;
  neutral-30: @neutral-30;
  neutral-40: @neutral-40;
  neutral-50: @neutral-50;
  neutral-60: @neutral-60;
  neutral-70: @neutral-70;
  neutral-80: @neutral-80;
  neutral-90: @neutral-90;
  neutral-100: @neutral-100;
  primary-main: @primary-main;
  primary-surface: @primary-surface;
  primary-border: @primary-border;
  primary-hover: @primary-hover;
  primary-pressed: @primary-pressed;
  primary-focus: @primary-focus;
  warning-main: @warning-main;
  warning-surface: @warning-surface;
  warning-border: @warning-border;
  warning-hover: @warning-hover;
  warning-pressed: @warning-pressed;
  danger-main: @danger-main;
  danger-surface: @danger-surface;
  danger-border: @danger-border;
  danger-hover: @danger-hover;
  danger-pressed: @danger-pressed;
  success-main: @success-main;
  success-surface: @success-surface;
  success-border: @success-border;
  success-hover: @success-hover;
  success-pressed: @success-pressed;
  info-main: @info-main;
  info-surface: @info-surface;
  info-border: @info-border;
  info-hover: @info-hover;
  info-pressed: @info-pressed;
  info-green: @info-green;
  secondary-cyan: @secondary-cyan;
};
each(@color-vars, {
    .text-color-@{key} {
      color: @value !important;
    }
});

/* 
 * spacing
 */
.make-space(@name, @value) {
  .p-@{name} {
    padding: ~'calc(@{value} * @{padding-size})' !important;
  }
  .pt-@{name} {
    padding-top: ~'calc(@{value} * @{padding-size})' !important;
  }
  .pl-@{name} {
    padding-left: ~'calc(@{value} * @{padding-size})' !important;
  }
  .pr-@{name} {
    padding-right: ~'calc(@{value} * @{padding-size})' !important;
  }
  .pb-@{name} {
    padding-bottom: ~'calc(@{value} * @{padding-size})' !important;
  }
  .pv-@{name} {
    padding-top: ~'calc(@{value} * @{padding-size})' !important;
    padding-bottom: ~'calc(@{value} * @{padding-size})' !important;
  }
  .ph-@{name} {
    padding-left: ~'calc(@{value} * @{padding-size})' !important;
    padding-right: ~'calc(@{value} * @{padding-size})' !important;
  }
  .m-@{name} {
    margin: ~'calc(@{value} * @{padding-size})' !important;
  }
  .mt-@{name} {
    margin-top: ~'calc(@{value} * @{padding-size})' !important;
  }
  .ml-@{name} {
    margin-left: ~'calc(@{value} * @{padding-size})' !important;
  }
  .mr-@{name} {
    margin-right: ~'calc(@{value} * @{padding-size})' !important;
  }
  .mb-@{name} {
    margin-bottom: ~'calc(@{value} * @{padding-size})' !important;
  }
  .mv-@{name} {
    margin-top: ~'calc(@{value} * @{padding-size})' !important;
    margin-bottom: ~'calc(@{value} * @{padding-size})' !important;
  }
  .mh-@{name} {
    margin-left: ~'calc(@{value} * @{padding-size})' !important;
    margin-right: ~'calc(@{value} * @{padding-size})' !important;
  }
}

.make-space(@name: 0, @value:0);
.make-space(@name: 0-25, @value:0.25);
.make-space(@name: 0-5, @value:0.5);
.make-space(@name: 0-75, @value:0.75);
.make-space(@name: 1, @value:1);
.make-space(@name: 2, @value:2);
.make-space(@name: 3, @value:3);
.make-space(@name: 4, @value:4);
.make-space(@name: 5, @value:5);
.make-space(@name: 6, @value:6);
.make-space(@name: 7, @value:7);
.make-space(@name: 8, @value:8);
.make-space(@name: 9, @value:9);
.make-space(@name: 10, @value:10);
.make-space(@name: 11, @value:11);
.make-space(@name: 12, @value:12);
.make-space(@name: 20, @value:20);

@font-alignments: left, center, right;
each(@font-alignments, {
    .text-@{value} {
      text-align: @value !important;
    }
});

/*
* border-radius 
*/
@radiuses: 4, 8, 16;
each(@radiuses, {
  .border-radius-@{value} {
    border-radius:  ~"@{value}px" !important;
    overflow: hidden;
  }
});

/*
 * background 
 */
each(@color-vars, {
  .bg-@{key} {
    background-color: @value !important;
  }
});

/*
 * object-fit 
 */
@fits: unset, contain, cover;
each(@fits, {
    .object-fit-@{value} {
      object-fit: @value !important;
    }
});

/*
 * cursor
 */
@cursors: pointer;
each(@cursors, {
    .cursor-@{value} {
      cursor: @value !important;
    }
});

.box-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-badge-count {
  box-shadow: unset !important;
}

.ant-table-thead > tr > th {
  background-color: @white;
}

.badge-disable {
  .ant-badge-count {
    border: 1px solid;
    color: @gray !important;
  }
}

.ant-badge-count {
  background-color: white;
  &:extend(.text-color-primary-purple);
  &:extend(.text-size-14);
  &:extend(.text-weight-medium);
}
.badge-inactive {
  .ant-badge-count {
    background-color: #f5f1fe;
  }
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.ql-editor {
  min-height: @min-height-editor !important;
}

.instagram-teks {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 0 7px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.instagram-embed-link {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}

/*
 * border color
 */
each(@color-vars, {
  .border-@{key} {
    border-color: @value !important;
  }
});

.full-width {
  width: 100%;
}

.border-none {
  border: none;
}

/*
 * width
 */
@width-sizes: 25, 50, 75, 100;
each(@width-sizes, {
     .w-@{value} {
       width: ~'@{value}%' !important;
     }
   });
/*
  * height
  */
@height-sizes: 25, 50, 75, 100;
each(@height-sizes, {
    .h-@{value} {
      height: ~'@{value}%' !important;
    }
  });

.disable-ant-modal-footer {
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-btns {
        display: none !important;
      }
    }
  }
}

@lines: 1, 2, 3, 4, 5;
each(@lines, {
  .line-clamp-@{value} {
    display: -webkit-box;
    -webkit-line-clamp: ~"@{value}";
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
});

.use-pointer {
  cursor: pointer;
}

.ant-table-thead .ant-table-cell {
  background-color: @blue-primary !important;
  color: #ffffff !important;
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border-radius: 42px !important;
// }

// .ant-input-affix-wrapper {
//   border-radius: 42px !important;
// }

// .ant-picker {
//   border-radius: 42px !important;
// }

// .ant-input {
//   border-radius: 42px !important;
// }

.ant-input-textarea .ant-input {
  border-radius: 5px !important;
}

.ant-modal-header {
  .ant-modal-title {
    color: #6320ee !important;
  }
  border-bottom: solid 0px !important;
}

.layout .header {
  box-shadow: 0 2px 8px #f0f1f2 !important;
  z-index: 10;
  position: sticky;
  top: 0px;
}

.font-semibold {
  font-weight: 600;
}

.pl-3,
.layout .ant-menu.ant-menu-sub {
  padding: 0px !important;
}

.ant-popover-title {
  display: none !important;
}
.ant-popover-inner-content {
  padding: 2px !important;
}

.ant-popover-arrow {
  display: none;
}

.user-profile {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #cde7ff;
  position: relative;
}

.ant-select-arrow,
.ant-picker-suffix,
.ant-input-suffix {
  color: @blue-primary;
}

.ant-table-tbody {
  padding: 0 !important;
}
.ant-table-tbody > tr > td {
  padding: 8px !important;
}

.ant-table-thead > tr > th {
  padding: 10px 10px !important;
  font-size: 14px;
  font-weight: 500;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-form-item-label {
  label {
    font-weight: 600;
  }
}

.news-launcher-item {
  .ant-space-item {
    width: 100%;
  }
}

.flex {
  display: flex;
}

.flex-vertical {
  flex-direction: column;
}

.items-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.user-card {
  border-radius: 10px;
  :hover {
    background-color: #1890ff;
    cursor: pointer;
  }
}

@btn-border-radius-base: 3px;@btn-border-radius-sm: 3px;@btn-primary-bg: #3b82f6;@btn-default-ghost-color: #3b82f6;@btn-default-ghost-border: #3b82f6;